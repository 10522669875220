import { useRouteLoaderData } from '@remix-run/react';
import type { loader as _appLoader } from '~/routes/_app';
import type { loader as rootLoader } from '~/root';

export function useRootData() {
    const data = useRouteLoaderData<typeof rootLoader>('root');

    if (!data) {
        throw new Error('Root data not found');
    }

    return data;
}

// TODO: Remove disable when hook is used (It will be used)
// eslint-disable-next-line import/no-unused-modules
export function useAppData() {
    const data = useRouteLoaderData<typeof _appLoader>('routes/_app');

    if (!data) {
        throw new Error('App data not found');
    }

    return data;
}
