export class UIConfigurationError extends Error {
    constructor(
        message: string,
        public statusCode: number = 500,
    ) {
        super(message);
        this.name = 'UIConfigurationError';
        Object.setPrototypeOf(this, Error.prototype);
    }
}
