import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesHTMLAttributes } from '../../types';

const sheetFooterVariants = cva(
    'rocco-sheet-footer flex flex-col-reverse gap-2 sm:flex-row sm:justify-end',
    {
        variants: {
            variant: {
                default: 'px-6 pb-5 pt-4 border-t border-gray-900/30',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const SheetFooter = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof sheetFooterVariants>) => (
    <div className={sheetFooterVariants({ variant })} {...props} />
);

SheetFooter.displayName = 'SheetFooter';
