import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const sheetOverlayVariants = cva('rocco-sheet-overlay', {
    variants: {
        variant: {
            default:
                'fixed inset-0 z-modalBackdrop bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SheetOverlay = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Overlay>,
    OmitStylesComponentProps<typeof SheetPrimitive.Overlay> &
        VariantProps<typeof sheetOverlayVariants>
>(({ variant, ...props }, ref) => (
    <SheetPrimitive.Overlay
        className={sheetOverlayVariants({ variant })}
        {...props}
        ref={ref}
    />
));

SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
