import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesHTMLAttributes } from '../../types';

const sheetHeaderVariants = cva('rocco-sheet-header flex flex-col space-y-2', {
    variants: {
        variant: {
            default:
                'px-6 pt-6 md:pt-7 pb-3 md:pb-4.5 border-b border-gray-900/30',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SheetHeader = ({
    variant,
    ...props
}: OmitStylesHTMLAttributes<HTMLDivElement> &
    VariantProps<typeof sheetHeaderVariants>) => (
    <div className={sheetHeaderVariants({ variant })} {...props} />
);

SheetHeader.displayName = 'SheetHeader';
