import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { SheetPortal } from './sheet-portal';
import { SheetOverlay } from './sheet-overlay';

import { X } from 'lucide-react';
import { cva, type VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const sheetVariants = cva(
    'rocco-sheet-content flex fixed justify-center p-0 md:p-6 outline-0 transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
    {
        variants: {
            side: {
                top: 'inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
                bottom: 'inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
                left: 'inset-y-0 left-0 h-full data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-[32rem]',
                right: 'inset-y-0 right-0 h-full data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-[32rem]',
            },
            zLevel: {
                default: 'z-modal',
                top: 'z-200',
            },
        },
        defaultVariants: {
            side: 'bottom',
            zLevel: 'default',
        },
    },
);

const sheetContentVariants = cva(
    'w-fit z-modal gap-4 backdrop-blur will-change-transform',
    {
        variants: {
            color: {
                default: 'bg-gray-100/85 shadow-lg',
                opaque: 'bg-white/95 shadow-lg',
            },
            radius: {
                default: 'rounded-t-2xl md:rounded-2xl',
                none: 'rounded-0',
            },
        },
        defaultVariants: {
            color: 'default',
            radius: 'default',
        },
    },
);

interface SheetContentProps
    extends OmitStylesComponentProps<typeof SheetPrimitive.Content>,
        VariantProps<typeof sheetVariants>,
        VariantProps<typeof sheetContentVariants> {
    hideDefaultCloseButton?: boolean;
    noOverlay?: boolean;
    closeContent?: () => void;
}

export const SheetContent = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Content>,
    SheetContentProps
>(
    (
        {
            side,
            children,
            noOverlay = false,
            color,
            radius,
            hideDefaultCloseButton,
            zLevel,
            closeContent,
            ...props
        },
        ref,
    ) => (
        <SheetPortal>
            {!noOverlay && <SheetOverlay />}
            <SheetPrimitive.Content
                ref={ref}
                className={sheetVariants({ side, zLevel })}
                {...props}
                onClick={() => {
                    closeContent?.();
                }}
            >
                <div
                    className={sheetContentVariants({ color, radius })}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                    {!hideDefaultCloseButton && (
                        <SheetPrimitive.Close className="absolute right-5 md:right-6 top-6 md:top-7 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
                            <X className="h-6 w-6" />
                            <span className="sr-only">Close</span>
                        </SheetPrimitive.Close>
                    )}
                </div>
            </SheetPrimitive.Content>
        </SheetPortal>
    ),
);

SheetContent.displayName = SheetPrimitive.Content.displayName;
