import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const sheetTitleVariants = cva('rocco-sheet-title', {
    variants: {
        variant: {
            default:
                'text-xl font-medium text-foreground text-center md:text-left',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SheetTitle = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Title>,
    OmitStylesComponentProps<typeof SheetPrimitive.Title> &
        VariantProps<typeof sheetTitleVariants>
>(({ variant, ...props }, ref) => (
    <SheetPrimitive.Title
        ref={ref}
        className={sheetTitleVariants({ variant })}
        {...props}
    />
));

SheetTitle.displayName = SheetPrimitive.Title.displayName;
