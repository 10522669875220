import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const sheetDescriptionVariants = cva('rocco-sheet-description', {
    variants: {
        variant: {
            default: 'text-sm text-muted-foreground',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SheetDescription = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Description>,
    OmitStylesComponentProps<typeof SheetPrimitive.Description> &
        VariantProps<typeof sheetDescriptionVariants>
>(({ variant, ...props }, ref) => (
    <SheetPrimitive.Description
        ref={ref}
        className={sheetDescriptionVariants({ variant })}
        {...props}
    />
));

SheetDescription.displayName = SheetPrimitive.Description.displayName;
