import { SearchIndexes } from '@modules-rocco/search/types/enum/search-indexes';
import type { ConstantLengthArray } from '@utils-rocco/types';
import type { RangeValueFilterStateKeys } from '@modules-rocco/filters/types/filter-states';

export const SEARCH_AGGREGATE_API = '/actions/search/aggregate';

export const RANGE_DIVISORS: Record<
    (typeof RangeValueFilterStateKeys)[number],
    number
> = {
    priceRange: 100,
    lengthRange: 1000,
    widthRange: 1000,
    heightRange: 1000,
    diameterRange: 1000,
    weightRange: 1000,
};

export const DEFAULT_PAGE_SIZE = 40;

export const DEFAULT_SOURCE_FILTER = [
    'id',
    'ArticleWordCount',
    'ArticleAuthor',
    'ArticleByArchiPro',
    'Categories',
    'CompanyLogos',
    'Created',
    'Link',
    'LiveDate',
    'CanPurchase',
    'LibraryThumbs',
    'LogoBackgroundColor',
    'PType',
    'ProductColours',
    'ProfessionalID',
    'Professional',
    'ProfessionalLink',
    'Thumb_lg',
    'Title',
    'DirectoryTile',
    'Services',
    'PricingDisplay',
    'MemberPricing',
    'TradePricing',
    'SearchLocations',
    'Thumbnail',
    'Email',
    'Phone',
    'Phone2',
    'HasAwards',
    'Visibilities',
    'DirectoryTiles',
    'DownloadFileCategories',
    'SEODescription',
    'TotalViewedCount',
    'TotalSavedCount',
    'EmbeddingNMSLIB',
    'EmbeddingContent',
    'MergedContentEmbedding',
    'MergedContent',
];

interface SearchCategoryConfig {
    children: string;
    mobileName?: string;
    category?: string;
    index?: SearchIndexes;
    noResults: string;
    categoryLink?: string;
    itemType: string;
}

export const SEARCH_CATEGORIES: ConstantLengthArray<SearchCategoryConfig, 5> = [
    {
        children: 'All',
        noResults: 'results',
        categoryLink: undefined,
        itemType: 'Result',
    },
    {
        children: 'Products',
        category: 'products',
        index: SearchIndexes.Products,
        noResults: 'products',
        categoryLink: '/products',
        itemType: 'Product',
    },
    {
        children: 'Projects',
        category: 'projects',
        index: SearchIndexes.Projects,
        noResults: 'projects',
        categoryLink: '/projects',
        itemType: 'Project',
    },
    {
        children: 'Professionals',
        mobileName: 'Pros',
        category: 'professionals',
        index: SearchIndexes.Professionals,
        noResults: 'professionals',
        categoryLink: '/professionals',
        itemType: 'Professional',
    },
    {
        children: 'Articles',
        category: 'articles',
        index: SearchIndexes.Articles,
        noResults: 'results',
        categoryLink: '/articles',
        itemType: 'Article',
    },
];
